import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import radial from "../../Assets/Projects/ebay.png";
import ibm from "../../Assets/Projects/ibm.png";
import demand_sheet from "../../Assets/Projects/demSheet.png";
import papa_johns from "../../Assets/Projects/PJI.png";
import rest from "../../Assets/Projects/rest.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={demand_sheet}
              isBlog={false}
              title="HCL Demand Sheet Helper"
              description="The tool is built with the help of Python & Pandas and designed to streamline the process of finding the perfect opportunities within a large and often chaotic Demand Sheet. With this tool, bench employees can easily identify and explore potential opportunities that match their skills, experience, and location preferences."
              ghLink="https://github.com/reactVishnu/HCL_Opportunity_Finder"
              demoLink="https://github.com/reactVishnu/HCL_Opportunity_Finder/blob/main/README.md"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={rest}
              isBlog={false}
              title="Recipe App Backend"
              description="I successfully designed and developed a robust Recipe Sharing Backend using Python, Django, DRF, Docker, GitHub Actions, Postgres, and TDD methodologies. This project encompassed the creation of an advanced REST API tailored specifically for recipe sharing, showcasing my proficiency in modern web development practices. "
              ghLink="https://github.com/reactVishnu/recipe-app-api"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ibm}
              isBlog={false}
              title="IBM Corp"
              description="Worked as a Full Stack Developer at IBM Corp, contributed to the continuous improvement of software development processes and best practices. Worked on bug fixes and  participated in the design and architectural decisions of the software, including defining system components and structures. Also Participated in code reviews to learn from more experienced team members and ensure code quality"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={papa_johns}
              isBlog={false}
              title="Papa John's Inc"
              description="Worked as a software engineer at Papa Johns, contributed in deploying build on physical servers, worked with shell scripts, pythonic scripts, sql database in a linux enviroment and keeping code up-to-date with technology changes, libraries, and dependencies. Also Identifying, reproducing, and logging bugs, as well as tracking their resolution and actively participated in Agile ceremonies like daily stand-up meetings, sprint planning sessions, and retrospectives"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={radial}
              isBlog={false}
              title="Radial (Formarly E-bay)"
              description="Worked as a Software engineer at Radial. Contributed to Testing Web app as well as backend and flow of Legacy Orders. Also performing manual testing to verify that code works as expected and is free of defects. During the last phase in the project, i get exposure of development with python and test driven development. I start brushing up my skills of programming and testing. This phase provided me with an opportunity to sharpen my programming skills and reinforce my commitment to writing robust and thoroughly tested code"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
