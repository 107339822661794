import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Vishnu Tiwari </span>
            from <span className="purple"> Delhi, India.</span>
            <br/>
            <br /> I am a Python Developer
            <br />
            <br/>
            Me currently working as a software engineer at HCL Noida and contributed to projects with renowned tech
            companies such as IBM, eBay, and the well-known pizza chain, Papa John's.
            <br />
            <br/>
            Skills:- Python, Django, DRF, React, Docker, SQL, Numpy, Pandas, Matplotlib, Seaborn, Linux
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Surfing Internet
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>
          <br/>
          <p style={{ color: "rgb(155 126 172)" }}>
            " Don't be afraid to debug, refactor, and optimize your journey to greatness." 💻🌟"{" "}
          </p>
          <footer className="blockquote-footer">Vishnu</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
