import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    DiReact,
    DiPython,
    DiDjango,
    DiDocker,
    DiMysql,
    DiJenkins,
    DiAws,
    DiDatabase,
} from "react-icons/di";
import {
    SiSqlite,
    SiLinux,
    SiJira,
    SiHtml5,
    SiCss3,
    SiNumpy,
    SiPandas,
} from "react-icons/si";
import {TbBrandDjango,} from "react-icons/tb";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiDjango />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <TbBrandDjango/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiDocker />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSqlite />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMysql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiLinux />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJenkins />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiJira />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHtml5 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCss3 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNumpy />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPandas />
      </Col>
        <Col xs={4} md={2} className="tech-icons">
        <DiAws />
      </Col>
        <Col xs={4} md={2} className="tech-icons">
        <DiDatabase />
      </Col>
    </Row>
  );
}

export default Techstack;
